<template>
  <div>
    <b-card>
      <b-col lg="10" md="10" class="mt-2">
        <div class="mt-2 mb-2">
          <h3 class="float-left">Réservations pour ce groupe</h3>
        </div>
      </b-col>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              placeholder="Recherche"
              type="text"
              class="d-inline-block"
            />
            <b-button
              variant="primary"
              class="ml-1"
              @click="addNewReservation"
              :disabled="disableRequest"
              v-if="canReserved"
            >
              <span class="text-nowrap">Ajouter une réservation</span>
            </b-button>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="reservations"
        @on-cell-click="onCellClick"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          searchFn: searchReservations,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        styleClass="vgt-table striped pointer"
      >
        >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'reservation_number'"
            class="text-nowrap"
          >
            <b-link
              :to="{
                path: `/groups/${currentGroupId}/reservation/${props.row.id}`,
              }"
              class="font-weight-bold"
            >
              #{{ props.row.reservation_number }}
            </b-link>
            <b-button
              v-show="showRegistrationLink(props.row)"
              v-b-tooltip.hover
              title="Copier le lien d'enregistrement public"
              size="small"
              variant="link"
              @click.prevent="copyRegistrationLink(props.row)"
            >
              <feather-icon icon="ClipboardIcon" />
            </b-button>
          </span>

          <span
            v-else-if="props.column.field === 'booking_number'"
            class="align-text-top text-capitalize small"
            v-html="getBookingNumbers(props.row)"
          >
          </span>

          <!-- Column: status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(getStatus(props.row))">
              {{ statusLabel(props.row.status) }}
            </b-badge>
          </span>

          <!-- Column: Payment -->
          <span v-else-if="props.column.field === 'payment'">
            {{ getPayment(props.row) }}
          </span>

          <!-- Column: Primary Contact -->
          <span v-else-if="props.column.field === 'primary_contact'">
            <span class="align-text-top text-capitalize">{{
              getPrimaryContact(props.row)
            }}</span>
          </span>

          <!-- Column: type -->
          <span v-else-if="props.column.field === 'send_to_pc_voyages'">
            <div class="col-12 text-center">
              <b-avatar
                circle
                :variant="
                  props.row.send_to_pc_voyages
                    ? 'light-success'
                    : 'light-secondary'
                "
                size="34"
              >
                <feather-icon icon="CheckCircleIcon" size="18" />
              </b-avatar>
            </div>
          </span>
          <!-- Column: user_id -->
          <span v-else-if="props.column.field === 'user_id'">
            <b-badge variant="light-secondary" pill>
              <b-avatar
                :src="props.row.avatar"
                :variant="avatarColors(flattenUUIDtoBase(props.row.user_id))"
                size="sm"
                class="mr-1"
              />
              <span class="align-text-top text-capitalize">{{
                getUser(props.row.user_id)
              }}</span>
            </b-badge>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'group-edit-reservation',
                    params: { reservation_id: props.row.id },
                  }"
                  v-if="
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                      Roles.ORGANIZATION_COORDINATOR.role,
                    ])
                  "
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Modifier</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="changeNumberReservation(props.row)"
                  v-if="
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                      Roles.ORGANIZATION_COORDINATOR.role,
                    ])
                  "
                >
                  <feather-icon icon="ListIcon" class="mr-50" />
                  <span v-if="props.row.booking_numbers.length > 0"
                    >Modifier les réservations Tour-opérateur</span
                  >
                  <span v-else>Ajouter des réservations Tour-opérateur</span>
                </b-dropdown-item>
                <ReservationNumberModal
                  v-if="currentReservation"
                  ref="res-number-modal"
                  :current-reservation="currentReservation"
                  :main-contact="getPrimaryContact(currentReservation)"
                  @submitReservationNumbers="submitReservationBookingNumbers"
                  @resetReservation="resetReservation"
                />

                <b-dropdown-item
                  v-if="tenantConfig.paymentEnabled"
                  @click="showReservationPaymentModal(props.row)"
                >
                  <feather-icon icon="DollarSignIcon" class="mr-50" />
                  <span>Inscrire un paiement</span>
                </b-dropdown-item>
                <ReservationPaymentModal
                  v-if="
                    tenantConfig.paymentEnabled &&
                    currentReservation &&
                    currentReservation == props.row
                  "
                  ref="res-payment-modal"
                  :reservation="currentReservation"
                  @submit="updateReservationPayments"
                  @cancel="resetReservation"
                />

                <b-dropdown-item
                  @click="noteReservation(props.row)"
                  v-if="
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                    ])
                  "
                >
                  <feather-icon icon="FileTextIcon" class="mr-50" />
                  <span v-if="props.row.notes">Modifier la note</span>
                  <span v-else>Ajouter une nouvelle note</span>
                </b-dropdown-item>
                <ReservationNoteModal
                  v-if="currentReservation"
                  ref="res-note-modal"
                  :current-reservation="currentReservation"
                  @submitReservationNote="submitReservationNote"
                />
                <b-dropdown-item
                  v-if="
                    tenantConfig.pcVoyagesEnabled &&
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                      Roles.ORGANIZATION_COORDINATOR.role,
                    ])
                  "
                  :disabled="props.row.status !== 'SUBMITTED'"
                  @click="sendReservationToPCVoyage(props.row)"
                >
                  <feather-icon icon="UploadIcon" class="mr-50" />
                  <span>Envoyer à PC Voyages</span>
                </b-dropdown-item>
                <ReservationOptionsModal
                  v-if="
                    tenantConfig.additionalOptionsEnabled && currentReservation
                  "
                  ref="reservation-options-modal"
                  :current-reservation="currentReservation"
                  :options="reservationOptions"
                  @close="reservationOptions = []"
                />
                <b-dropdown-item
                  v-if="
                    tenantConfig.additionalOptionsEnabled &&
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                      Roles.ORGANIZATION_COORDINATOR.role,
                    ])
                  "
                  @click="showReservationOptionsModal(props.row)"
                >
                  <feather-icon icon="ShoppingBagIcon" class="mr-50" />
                  <span>Options sélectionnées</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-if="
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.ORGANIZATION_ADMIN.role,
                      Roles.ORGANIZATION_COORDINATOR.role,
                    ])
                  "
                  @click="
                    deleteReservation(
                      props.row.id,
                      props.row.status === 'SUBMITTED'
                    )
                  "
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>
                    {{
                      props.row.status === "CANCELLED" ? "Supprimer" : "Annuler"
                    }}
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showRequestModal(props.row)"
                  v-if="
                    canViewBasedOnRole(currentAuthUser, [
                      Roles.AGENCY_ADMIN.role,
                      Roles.AGENCY_AGENT.role,
                    ])
                  "
                >
                  <feather-icon icon="HelpCircleIcon" class="mr-50" />
                  <span>Demande modification/annulation</span>
                </b-dropdown-item>
                <ReservationRequestModal
                  :ref="`reservation-request-modal-ref-${props.row.id}`"
                  :reservation="reservationRequest"
                />
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Indiquer 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> des {{ props.total }} données </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  BCol,
  VBTooltip,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  BIcon,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { ref, reactive, nextTick, computed } from "@vue/composition-api";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";

import router from "@/router";
import store from "@/store";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import Ripple from "vue-ripple-directive";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import reservationSearch from "@/views/mixin/reservationSearch";
import reservationNote from "@/views/mixin/reservationNote";
import mixpanel from "mixpanel-browser";
import _ from "lodash";

import ReservationOptionsModal from "@/views/groups/group/ReservationOptions/ReservationOptionsModal.vue";
import ReservationNumberModal from "./EditReservation/EditNumberReservation/ReservationNumberModal";
import ReservationNoteModal from "./EditReservation/EditNotesReservation/ReservationNoteModal";
import ReservationPaymentModal from "./EditReservation/ReservationPaymentModal";
import ReservationRequestModal from "@/modules/requests/components/reservationRequestModal";

import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
import { GroupAdditionalOptionService } from "@/views/groups/services/GroupAdditionalOptionService.js";
import { useReservationApi } from "@/modules/reservation/composables/use-reservation-api";
import { useGroupApi } from "@/modules/group/composables/use-group-api";

export const additionalOptionService = new GroupAdditionalOptionService();
export const groupReservationService = new GroupReservationService();
export default {
  mixins: [reservationSearch, reservationNote],
  components: {
    BCard,
    VueGoodTable,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    BLink,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BIcon,
    BDropdownItem,
    ReservationNumberModal,
    ReservationRequestModal,
    ReservationNoteModal,
    ReservationPaymentModal,
    ReservationOptionsModal,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    currentGroupId: {
      type: String,
      required: true,
    },
    currentSaleStatus: {
      type: String,
    },
  },
  setup(props) {
    const { getGroupBasicInformation } = useGroupApi();
    const { refOf } = useApplicationContext();
    const { sendToPCVoyage } = useReservationApi();

    const currentGroupId = router.currentRoute.params.id;

    const statusOptions = [
      { label: "completed", value: "completed" },
      { label: "in_progress", value: "in_progress" },
    ];

    const canReserved = computed(() => {
      let canReserve = false;

      if (props.currentSaleStatus == "ON_SALE") {
        canReserve = true;
      }

      return canReserve;
    });

    let tenantConfig = reactive({
      paymentEnabled: false,
      pcVoyagesEnabled: false,
      additionalOptionsEnabled: false,
    });

    try {
      tenantConfig.paymentEnabled =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.payment_enable;
      tenantConfig.pcVoyagesEnabled =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.pcvoyages_enable;
      tenantConfig.additionalOptionsEnabled =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.additional_options_enable;
    } catch (e) {
      console.error(e);
    }

    const reservationRequest = ref();

    const getPayment = (reservation) => {
      if (reservation.payment) {
        let amount = parseFloat(reservation.payment.amount) / 100;
        return amount.toFixed(2) + " $";
      }
      return "";
    };

    const getUser = (user_id) => {
      let user = users.value.find((element) => {
        return element.id === user_id;
      });
      return user ? user.full_name : "N/A";
    };

    const showRequestModal = (reservation) => {
      reservationRequest.value = reservation;
      nextTick(() => {
        refOf(
          `reservation-request-modal-ref-${reservation.id}`
        ).$children[0].show();
      });
    };

    const reservations = ref([]);
    const users = ref([]);

    const fetchReservations = async () => {
      reservations.value = [];
      const groupDetails = await getGroupBasicInformation(currentGroupId);

      groupReservationService
        .getReservations(currentGroupId)
        .then((response) => {
          response.data.forEach((reservation) => {
            reservation.departure_date = groupDetails.departureDate;
            reservations.value.push(reservation);
          });
        });
    };
    const getUsers = async () => {
      let response = await groupReservationService.getAllMembersOfOrg();
      users.value = response.data;
    };

    const onCellClick = (params) => {
      if (params.column.field != "action") {
        router.push({
          name: "group-edit-reservation",
          params: { reservation_id: params.row.id, id: currentGroupId },
        });
      }
    };

    let columns = [
      {
        label: "Numéro de réservation",
        field: "reservation_number",
        type: "number",
        tdClass: "text-left",
        thClass: "text-left",
      },
      {
        label: "Statut",
        field: "status",
      },
      {
        label: "Contact principal",
        field: "primary_contact",
        sortFn: (a, b, col, rowX, rowY) => {
          return reservationSearch.methods
            .getPrimaryContact(rowX)
            .localeCompare(reservationSearch.methods.getPrimaryContact(rowY));
        },
      },
      {
        label: "Réservations Tour-opérateur",
        field: "booking_number",
        sortFn: (a, b, col, rowX, rowY) => {
          const bookingNumbersA = rowX.booking_numbers
            ? rowX.booking_numbers.join()
            : "";
          const bookingNumbersB = rowY.booking_numbers
            ? rowY.booking_numbers.join()
            : "";
          return bookingNumbersA.localeCompare(bookingNumbersB);
        },
      },
      {
        label: "Conseiller",
        field: "user_id",
        sortFn: (a, b) => {
          const userA = getUser(a);
          const userB = getUser(b);
          return userA.localeCompare(userB);
        },
      },
      {
        label: "Action",
        field: "action",
      },
    ];
    if (tenantConfig.pcVoyagesEnabled) {
      columns.splice(3, 0, {
        label: "PC Voyage",
        field: "send_to_pc_voyages",
      });
    }
    if (tenantConfig.paymentEnabled) {
      columns.splice(2, 0, {
        label: "Paiement",
        field: "payment",
        type: "text",
        sortFn: (a, b) => {
          const paymentA = a ? a.amount : null;
          const paymentB = b ? b.amount : null;

          if (paymentA == null) {
            return 1;
          } else if (paymentB == null) {
            return -1;
          } else {
            return paymentB - paymentA;
          }
        },
      });
    }

    fetchReservations();
    getUsers();
    const { displaySuccessMessage, displayInfoMessage, displayErrorMessage } =
      useToastNotification();

    mixpanel.track("Access group reservations", {
      group_id: props.currentGroupId,
    });

    return {
      columns,
      fetchReservations,
      statusOptions,
      reservations,
      users,
      getUsers,
      displayErrorMessage,
      displaySuccessMessage,
      displayInfoMessage,
      showRequestModal,
      reservationRequest,
      onCellClick,
      canReserved,
      tenantConfig,
      sendToPCVoyage,
      getPayment,
      getUser,
    };
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      currentAuthUser: null,
      currentReservation: null,
      searchTerm: this.$route.query.search || "",
      typeOptions: ["TRANSPORT", "all_inclusive"],
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      planOptions: [],
      disableRequest: false,
      Roles,
      reservationOptions: [],
    };
  },
  created() {
    this.currentAuthUser =
      store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;
  },
  methods: {
    fetchReservationOptions(groupId, reservationId) {
      additionalOptionService
        .getReservationOptions(groupId, reservationId)
        .then((response) => {
          this.reservationOptions = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    resetReservation() {
      this.currentReservation = null;
      this.fetchReservations();
    },
    canViewBasedOnRole(currentRole, allowedRoles) {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles);
    },
    async submitReservationBookingNumbers(reservation, bookingNumbers) {
      try {
        await groupReservationService.updateReservationBookingNumbers(
          this.currentGroupId,
          reservation.id,
          bookingNumbers
        );
        this.displaySuccessMessage("Réservation modifiée");
        this.$refs["res-number-modal"].$refs.modalReservationNumber.hide();
        this.fetchReservations();
      } catch (e) {
        this.displayErrorMessage("La modification a échoué");
      } finally {
        this.currentReservation = null;
      }
    },
    getBookingNumbers(reservation) {
      let bookingNumberString = "";
      if (reservation.booking_numbers.length > 0) {
        reservation.booking_numbers.forEach((bookingNumber) => {
          bookingNumberString +=
            bookingNumber.operator + ": " + bookingNumber.value + "<br>";
        });
      } else {
        bookingNumberString = "À venir";
      }
      return bookingNumberString;
    },

    getStatus(reservation) {
      if (reservation.status) {
        return reservation.status;
      } else {
        return "in_progress";
      }
    },
    deleteReservation(id, showCancellationLabels) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: showCancellationLabels
          ? "Procéder à l'annulation"
          : "Oui, supprimer!",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          groupReservationService
            .deleteReservation(this.currentGroupId, id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: showCancellationLabels ? "Annulé!" : "Supprimé!",
                text:
                  "Votre réservation a été " +
                  (showCancellationLabels ? "annulée." : "supprimée."),
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.fetchReservations();
              this.$emit("update");
            });
        }
      });
    },
    showReservationOptionsModal(reservation) {
      this.currentReservation = reservation;
      this.fetchReservationOptions(reservation.group_id, reservation.id);

      this.$nextTick(() => {
        this.$refs["reservation-options-modal"].$children[0].show();
      });
    },
    changeNumberReservation(reservation) {
      this.currentReservation = reservation;
      this.$nextTick(() => {
        this.$refs["res-number-modal"].$children[0].show();
      });
    },
    noteReservation(reservation) {
      this.currentReservation = reservation;
      this.$nextTick(() => {
        this.$refs["res-note-modal"].$children[0].show();
      });
    },
    showReservationPaymentModal(reservation) {
      this.currentReservation = reservation;
      this.$nextTick(() => {
        this.$refs["res-payment-modal"].$children[0].show();
      });
    },
    async updateReservationPayments(reservation, payments) {
      try {
        payments.forEach(async (p) => {
          if (p.id == undefined || p.id == null) {
            await groupReservationService.createReservationPayment(
              this.currentGroupId,
              reservation.id,
              p
            );
          }
        });

        let paymentsToDelete = [];
        reservation.reservation_payments.forEach(async (p) => {
          const updatedPayment = payments.find((x) => x.id == p.id);
          if (!updatedPayment) {
            paymentsToDelete.push(p);
          } else {
            let date = p.date;
            if (Array.isArray(date)) {
              date = dayjs(date.join("-"));
            }

            if (
              date != updatedPayment.date ||
              p.amount != updatedPayment.amount ||
              p.type != updatedPayment.type
            ) {
              await groupReservationService.updateReservationPayment(
                this.currentGroupId,
                reservation.id,
                updatedPayment.id,
                {
                  amount: updatedPayment.amount,
                  date: updatedPayment.date,
                  type: updatedPayment.type,
                }
              );
            }
          }
        });

        paymentsToDelete.forEach(async (p) => {
          await groupReservationService.deleteReservationPayment(
            this.currentGroupId,
            reservation.id,
            p.id
          );
        });
        this.displaySuccessMessage("Paiements mis à jour");
        setTimeout(() => {
          this.fetchReservations();
        }, 500);
      } catch (e) {
        this.displayErrorMessage("La modification a échoué");
        console.error(e);
      }
      this.$emit("update");
    },
    async changeStatus(status, reservation) {
      let statusObj = {
        status: status,
      };
      if (status === "SUBMITTED") {
        if (this.checkIfreservationFilledCorrectly(reservation)) {
          try {
            await groupReservationService.updateStatus(
              this.currentGroupId,
              reservation.id,
              statusObj
            );
            this.displaySuccessMessage("Réservation modifiée");
            this.fetchReservations();
          } catch (e) {
            this.displayErrorMessage("La modification a échoué");
            console.error(e);
          }
        } else {
          this.displayErrorMessage(
            "Vous devez remplir toutes les informations concernant les voyageurs"
          );
        }
      } else {
        try {
          let response = await groupReservationService.updateStatus(
            this.currentGroupId,
            reservation,
            statusObj
          );
          this.displaySuccessMessage("Réservation modifiée");
          this.fetchReservations();
        } catch (e) {
          this.displayErrorMessage("La modification a échoué");
          console.error(e);
        }
      }

      this.$emit("update");
    },
    checkIfreservationFilledCorrectly(reservation) {
      let i = 0;
      let reservation_packages = reservation.package_reservations;
      for (let counter = 0; counter < reservation_packages.length; counter++) {
        let reservation_package = reservation_packages[counter];
        if (
          reservation_package.travelers.length <
          this.typeToNumberOftravelers(reservation_package.occupation_type.type)
        ) {
          i++;
        }
      }
      if (i > 0) {
        return false;
      } else {
        return true;
      }
    },
    async addNewReservation() {
      this.disableRequest = true;
      const reservationTemp = {
        insurance_contract_number: null,
        insurer_name: null,
        insurance_types: ["medical"],
        has_trip_insurance: false,
        has_membership: false,
        extra_information: "",
        wants_package_modification: true,
        membership_number: "",
        membership_company: "",
        wants_insurance_submission: false,
        seat_selection: null,
        status: "DRAFT",
      };
      try {
        let response = await groupReservationService.postReservationAll(
          this.currentGroupId,
          reservationTemp
        );
        let match = response.headers.location.split("/");
        let reservation_id = match[7];
        this.displaySuccessMessage("Réservation créée avec succès");
        await this.$router.push({
          name: "group-edit-reservation",
          params: { id: this.currentGroupId, reservation_id: reservation_id },
        });
      } catch (e) {
        this.displayErrorMessage("L'ajout a échoué");
        console.error(e);
      } finally {
        this.$emit("update");
      }
    },
    typeToNumberOftravelers(type) {
      const types = {
        SINGLE: 1,
        DOUBLE: 2,
        TRIPLE: 3,
        QUADRUPLE: 4,
      };
      return types[type];
    },
    showRegistrationLink(reservation) {
      const publicUrlEnabled =
        store.state[AUTH_STORE_NAMESPACE].orgSettings?.public_url_enable ===
        true;
      const isPublicReservation =
        reservation.public_access_token &&
        reservation.contact_email &&
        reservation.contact_phone;

      return publicUrlEnabled && isPublicReservation;
    },
    copyRegistrationLink(reservation) {
      try {
        const basePart = `${window.location.origin}/public/groups/${this.currentGroupId}`;
        const reservationPart = `reservations/${reservation.id}`;
        const tokenPart = `token=${reservation.public_access_token}`;

        const registrationUrl = `${basePart}/${reservationPart}?${tokenPart}`;
        navigator.clipboard
          .writeText(registrationUrl)
          .then(() => this.displayInfoMessage("Lien pour client copié!"));

        return false;
      } catch (e) {
        console.error(e);
      }
    },
    sendReservationToPCVoyage(reservation) {
      this.$swal({
        title: "Synchronisation à PC Voyages",
        html: `Vous êtes sur le point de transmettre la réservation <strong>#${reservation.reservation_number}</strong> vers PC Voyages.<br><br>Voulez-vous continuer?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.sendToPCVoyage(this.currentGroupId, reservation.id)
            .then((response) => {
              if (response.data.success) {
                this.fetchReservations();
                this.$emit("update");

                this.$swal({
                  icon: "success",
                  title: "Transmission complétée",
                  html: `La réservation a été transmise avec succès. Elle apparaîtra dans PC Voyages avec le numéro suivant: <strong>${response.data.pnrnum}</strong>`,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              } else {
                console.error(response.data.message);

                this.$swal({
                  icon: "error",
                  title: "Réservation non transmise",
                  text: `Une erreur s'est produite lors de la transmission de la réservation. Veuillez réessayer.`,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            })
            .catch((e) => {
              console.error(e);

              this.$swal({
                icon: "error",
                title: "Réservation non transmise",
                text: `Une erreur s'est produite lors de la transmission de la réservation. Veuillez réessayer.`,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        DRAFT: "light-warning",
        CANCELLED: "light-danger",
        SUBMITTED: "light-success",
      };
      return (status) => statusColor[status];
    },
    statusLabel() {
      const statusLabel = {
        DRAFT: "Brouillon",
        CANCELLED: "Annulée",
        PAYMENT_IN_PROGRESS: "Paiement en cours",
        SUBMITTED: "Complétée",
      };
      return (status) => statusLabel[status];
    },
    typeVariant() {
      const typeColor = {
        TRANSPORT: "danger",
        all_inclusive: "success",
      };
      return (type) => typeColor[type];
    },
    typeLabel() {
      const typeLabel = {
        TRANSPORT: "Transport",
        all_inclusive: "All Inclusive",
      };
      return (type) => typeLabel[type];
    },
    typeIcons() {
      const typeIcons = {
        TRANSPORT: "DropletIcon",
        all_inclusive: "SunIcon",
      };
      return (type) => typeIcons[type];
    },
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;
      return (character) =>
        Math.round(parseInt(character.slice(-1), UUID_BASE) / BASE);
    },
    avatarColors() {
      const avatarColors = {
        1: "primary",
        2: "dark",
        3: "secondary",
        4: "success",
        5: "danger",
        6: "warning",
        7: "info",
        8: "secondary",
      };
      return (character) => avatarColors[character];
    },
  },
  watch: {
    searchTerm: _.debounce(function (newValue) {
      if (newValue != this.$route.query.search) {
        this.$router.push({ query: { search: newValue } });
      }
    }, 300),
    "$route.query.search": {
      handler: function (value) {
        if (value == undefined) {
          value = "";
        }

        if (this.searchTerm != value) {
          this.searchTerm = value || "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.pointer {
  cursor: pointer;
}
</style>
