var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-col',{staticClass:"mt-2",attrs:{"lg":"10","md":"10"}},[_c('div',{staticClass:"mt-2 mb-2"},[_c('h3',{staticClass:"float-left"},[_vm._v("Réservations pour ce groupe")])])]),_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Recherche","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),(_vm.canReserved)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary","disabled":_vm.disableRequest},on:{"click":_vm.addNewReservation}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Ajouter une réservation")])]):_vm._e()],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.reservations,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
        searchFn: _vm.searchReservations,
      },"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"styleClass":"vgt-table striped pointer"},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'reservation_number')?_c('span',{staticClass:"text-nowrap"},[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
              path: ("/groups/" + _vm.currentGroupId + "/reservation/" + (props.row.id)),
            }}},[_vm._v(" #"+_vm._s(props.row.reservation_number)+" ")]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRegistrationLink(props.row)),expression:"showRegistrationLink(props.row)"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Copier le lien d'enregistrement public","size":"small","variant":"link"},on:{"click":function($event){$event.preventDefault();return _vm.copyRegistrationLink(props.row)}}},[_c('feather-icon',{attrs:{"icon":"ClipboardIcon"}})],1)],1):(props.column.field === 'booking_number')?_c('span',{staticClass:"align-text-top text-capitalize small",domProps:{"innerHTML":_vm._s(_vm.getBookingNumbers(props.row))}}):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(_vm.getStatus(props.row))}},[_vm._v(" "+_vm._s(_vm.statusLabel(props.row.status))+" ")])],1):(props.column.field === 'payment')?_c('span',[_vm._v(" "+_vm._s(_vm.getPayment(props.row))+" ")]):(props.column.field === 'primary_contact')?_c('span',[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(_vm.getPrimaryContact(props.row)))])]):(props.column.field === 'send_to_pc_voyages')?_c('span',[_c('div',{staticClass:"col-12 text-center"},[_c('b-avatar',{attrs:{"circle":"","variant":props.row.send_to_pc_voyages
                  ? 'light-success'
                  : 'light-secondary',"size":"34"}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"18"}})],1)],1)]):(props.column.field === 'user_id')?_c('span',[_c('b-badge',{attrs:{"variant":"light-secondary","pill":""}},[_c('b-avatar',{staticClass:"mr-1",attrs:{"src":props.row.avatar,"variant":_vm.avatarColors(_vm.flattenUUIDtoBase(props.row.user_id)),"size":"sm"}}),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(_vm.getUser(props.row.user_id)))])],1)],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
                  _vm.canViewBasedOnRole(_vm.currentAuthUser, [
                    _vm.Roles.ORGANIZATION_ADMIN.role,
                    _vm.Roles.ORGANIZATION_COORDINATOR.role ])
                )?_c('b-dropdown-item',{attrs:{"to":{
                  name: 'group-edit-reservation',
                  params: { reservation_id: props.row.id },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Modifier")])],1):_vm._e(),(
                  _vm.canViewBasedOnRole(_vm.currentAuthUser, [
                    _vm.Roles.ORGANIZATION_ADMIN.role,
                    _vm.Roles.ORGANIZATION_COORDINATOR.role ])
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeNumberReservation(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ListIcon"}}),(props.row.booking_numbers.length > 0)?_c('span',[_vm._v("Modifier les réservations Tour-opérateur")]):_c('span',[_vm._v("Ajouter des réservations Tour-opérateur")])],1):_vm._e(),(_vm.currentReservation)?_c('ReservationNumberModal',{ref:"res-number-modal",attrs:{"current-reservation":_vm.currentReservation,"main-contact":_vm.getPrimaryContact(_vm.currentReservation)},on:{"submitReservationNumbers":_vm.submitReservationBookingNumbers,"resetReservation":_vm.resetReservation}}):_vm._e(),(_vm.tenantConfig.paymentEnabled)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showReservationPaymentModal(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DollarSignIcon"}}),_c('span',[_vm._v("Inscrire un paiement")])],1):_vm._e(),(
                  _vm.tenantConfig.paymentEnabled &&
                  _vm.currentReservation &&
                  _vm.currentReservation == props.row
                )?_c('ReservationPaymentModal',{ref:"res-payment-modal",attrs:{"reservation":_vm.currentReservation},on:{"submit":_vm.updateReservationPayments,"cancel":_vm.resetReservation}}):_vm._e(),(
                  _vm.canViewBasedOnRole(_vm.currentAuthUser, [
                    _vm.Roles.ORGANIZATION_ADMIN.role ])
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.noteReservation(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"FileTextIcon"}}),(props.row.notes)?_c('span',[_vm._v("Modifier la note")]):_c('span',[_vm._v("Ajouter une nouvelle note")])],1):_vm._e(),(_vm.currentReservation)?_c('ReservationNoteModal',{ref:"res-note-modal",attrs:{"current-reservation":_vm.currentReservation},on:{"submitReservationNote":_vm.submitReservationNote}}):_vm._e(),(
                  _vm.tenantConfig.pcVoyagesEnabled &&
                  _vm.canViewBasedOnRole(_vm.currentAuthUser, [
                    _vm.Roles.ORGANIZATION_ADMIN.role,
                    _vm.Roles.ORGANIZATION_COORDINATOR.role ])
                )?_c('b-dropdown-item',{attrs:{"disabled":props.row.status !== 'SUBMITTED'},on:{"click":function($event){return _vm.sendReservationToPCVoyage(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UploadIcon"}}),_c('span',[_vm._v("Envoyer à PC Voyages")])],1):_vm._e(),(
                  _vm.tenantConfig.additionalOptionsEnabled && _vm.currentReservation
                )?_c('ReservationOptionsModal',{ref:"reservation-options-modal",attrs:{"current-reservation":_vm.currentReservation,"options":_vm.reservationOptions},on:{"close":function($event){_vm.reservationOptions = []}}}):_vm._e(),(
                  _vm.tenantConfig.additionalOptionsEnabled &&
                  _vm.canViewBasedOnRole(_vm.currentAuthUser, [
                    _vm.Roles.ORGANIZATION_ADMIN.role,
                    _vm.Roles.ORGANIZATION_COORDINATOR.role ])
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showReservationOptionsModal(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ShoppingBagIcon"}}),_c('span',[_vm._v("Options sélectionnées")])],1):_vm._e(),(
                  _vm.canViewBasedOnRole(_vm.currentAuthUser, [
                    _vm.Roles.ORGANIZATION_ADMIN.role,
                    _vm.Roles.ORGANIZATION_COORDINATOR.role ])
                )?_c('b-dropdown-item',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.deleteReservation(
                    props.row.id,
                    props.row.status === 'SUBMITTED'
                  )}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v(" "+_vm._s(props.row.status === "CANCELLED" ? "Supprimer" : "Annuler")+" ")])],1):_vm._e(),(
                  _vm.canViewBasedOnRole(_vm.currentAuthUser, [
                    _vm.Roles.AGENCY_ADMIN.role,
                    _vm.Roles.AGENCY_AGENT.role ])
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.showRequestModal(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"HelpCircleIcon"}}),_c('span',[_vm._v("Demande modification/annulation")])],1):_vm._e(),_c('ReservationRequestModal',{ref:("reservation-request-modal-ref-" + (props.row.id)),attrs:{"reservation":_vm.reservationRequest}})],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Indiquer 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '20']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" des "+_vm._s(props.total)+" données ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_vm._v(" > ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }